<template>
  <div class="main-box">
    <el-card class="box-card" shadow="never">
      接口调用记录
    </el-card>
  </div>
</template>

<script>
export default {
  name: "InterfaceLog"
}
</script>

<style scoped>

</style>
